/* eslint-disable jsx-a11y/no-redundant-roles */
import { useContext, useEffect, useState } from "react";

import { ChevronRightIcon } from "@heroicons/react/20/solid";

import { ApiContext } from "../../providers/ApiProvider";

import DetailsSlideOver from "../ui/DetailsSlideOver";
import LoadingSpinner from "../ui/LoadingSpinner";
import Panel from "../ui/Panel/Panel";
import PanelBody from "../ui/Panel/PanelBody";
import PanelHeader from "../ui/Panel/PanelHeader";
import StackedList from "../ui/StackedList";

const ActionItems = () => {
  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [showResources, setShowResources] = useState({ show: false, resources: [] });

  useEffect(() => {
    api.client
      .get("/recommendations")
      .then((resp) => {
        setActions(resp.data.patients_recommendations);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client]);

  const buildItem = (item) => {
    const content = !!item.content ? item.content : item.recommendation.content;

    return (
      <>
        <div className="flex min-w-0 gap-x-4">{content}</div>
        <div className="flex shrink-0 items-center gap-x-4">
          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
        </div>
      </>
    );
  };

  return (
    <>
      <Panel>
        <PanelHeader>Action Items</PanelHeader>
        <PanelBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <StackedList
              items={actions}
              renderFunc={buildItem}
              onClick={(action) => setShowResources({ show: true, resources: action.recommendation.resources })}
            />
          )}
        </PanelBody>
      </Panel>
      {showResources.show && (
        <DetailsSlideOver
          open={showResources.show}
          onClose={() => setShowResources({ show: false, resources: null })}
          title="Action Resources"
        >
          <ul
            role="list"
            className="mx-5 divide-y divide-gray-100 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
          >
            {showResources.resources.map((r) => (
              <li key={r.content} className="flex cursor-pointer justify-between px-3 py-4 sm:px-4 sm:py-5">
                <div className="flex min-w-0 gap-x-4">
                  <a href={r.link} className="cursor-pointer" target="_blank" rel="noreferrer">
                    {r.content}
                  </a>
                  <div className="flex shrink-0 items-center gap-x-4">
                    <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </DetailsSlideOver>
      )}
    </>
  );
};

export default ActionItems;
