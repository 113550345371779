import { useContext } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import TopNav from "./TopNav";

import { AuthContext } from "../../providers/AuthProvider";

import useNotification from "../../hooks/useNotification";

import Notification from "../ui/Notification";
import Footer from "../ui/Footer";

const AuthedLayout = () => {
  const auth = useContext(AuthContext);

  const location = useLocation();

  const { message, subMessage, visible, hide, isError } = useNotification();

  if (!auth.isLoggedIn()) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <TopNav />

        <main className="mt-5 flex-1 relative overflow-y-auto focus:outline-none">
          <Outlet />
        </main>
        <Footer />
      </div>
      {visible && (
        <Notification message={message} subMessage={subMessage} show={visible} onClose={hide} isError={isError} />
      )}
    </div>
  );
};

export default AuthedLayout;
