import { useEffect, useState } from "react";

import { useDebounce } from "@uidotdev/usehooks";

import { classNames } from "../../utils/cssUtils";

import LoadingSpinner from "../ui/LoadingSpinner";

const defaultAnswer = (question, answer) => {
  if (!!answer) {
    return !!question.multi_options ? answer.multi_options : answer.options;
  } else {
    return !!question.multi_options ? { multi_options: [] } : { options: [] };
  }
};

const MultipleOptions = (props) => {
  const { question, onChange } = props;

  const [answer, setAnswer] = useState(defaultAnswer(question, props.answer));
  const [checked, setChecked] = useState({});
  const [firstRender, setFirstRender] = useState(true);
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [options, setOptions] = useState([]);

  const debounce = useDebounce(answer, 1000);

  // NOTE only needs to happen on load
  useEffect(() => {
    setIsLoading(true);
    let opts = [];

    if (!!question.multi_options) {
      opts = question.multi_options.sort((a, b) => a.position - b.position);
    } else {
      opts = question.options.sort((a, b) => a.position - b.position);
    }

    setOptions([...opts]);
    setIsLoading(false);
  }, [question]);

  useEffect(() => {
    if (!answer) return;

    setIsLoading(true);
    let chOpts = {};

    let answerOpts = [];

    if (!!answer.multi_options) {
      answerOpts = answer?.multi_options || [];
    } else {
      answerOpts = answer?.options || [];
    }

    options.forEach((opt) => {
      if (answerOpts.findIndex((m) => m.id === opt.id) !== -1) {
        chOpts[opt.id] = true;
      } else {
        chOpts[opt.id] = false;
      }
    });

    setChecked({ ...chOpts });
    setIsLoading(false);
  }, [answer, options]);

  useEffect(() => {
    if (firstRender || answer === props.answer) {
      setFirstRender(false);
      return;
    }

    if (debounce) {
      if (!!question.multi_options) {
        onChange(JSON.stringify(debounce.multi_options.map((opt) => opt.id)));
      } else {
        onChange(JSON.stringify(debounce.options.map((opt) => opt.id)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const handleChangeAnswer = (opt, isChecked, e) => {
    e.preventDefault();

    if (isChecked) {
      if (question.multi_options) {
        setAnswer((prev) => {
          const res = [...prev.multi_options];
          return { ...prev, multi_options: [...res, opt] };
        });
      } else {
        setAnswer((prev) => {
          const res = [...prev.options];
          return { ...prev, options: [...res, opt] };
        });
      }
      setChecked((prev) => {
        const res = { ...prev };
        res[opt.id] = true;

        return { ...res };
      });
    } else {
      if (question.multi_options) {
        setAnswer((prev) => {
          const res = prev.multi_options.filter((p) => p.id !== opt.id);
          return { ...prev, multi_options: [...res] };
        });
      } else {
        setAnswer((prev) => {
          const res = prev.options.filter((p) => p.id !== opt.id);
          return { ...prev, options: [...res] };
        });
      }
      setChecked((prev) => {
        const res = { ...prev };
        res[opt.id] = false;

        return { ...res };
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="w-full flex flex-col gap-2">
          {options.map((opt) => (
            <div
              key={opt.id}
              onClick={(e) => handleChangeAnswer(opt, !checked[opt.id], e)}
              className={classNames(
                checked[opt.id] ? "border-indigo-600 bg-indigo-100" : "border-slate-200 bg-slate-50",
                "relative flex items-start p-3 border rounded-lg cursor-pointer hover:bg-indigo-100"
              )}
            >
              <div className="flex h-6 items-center">
                <input
                  id={opt.id}
                  name={opt.id}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                  checked={checked[opt.id] === true}
                  onChange={(e) => handleChangeAnswer(opt, e.target.checked, e)}
                />
              </div>
              <div className="ml-3 text-lg sm:text-base leading-6">
                <label htmlFor={opt.id} className="font-medium text-gray-900 cursor-pointer">
                  {opt.content}
                </label>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default MultipleOptions;
