/* eslint-disable jsx-a11y/anchor-is-valid */
import { classNames } from "../../utils/cssUtils";

const TabMenu = (props) => {
  const { tabs, curTab, onChange } = props;

  const handleTabChange = (tab) => {
    if (!!tab.href) {
      window.open(tab.href, tab.target || "_blank", "noopener,noreferrer");
    } else {
      onChange(tab);
    }
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => handleTabChange(JSON.parse(e.target.value))}
          defaultValue={curTab}
        >
          {tabs.map((tab) => (
            <option key={tab.value} value={JSON.stringify({ name: tab.name, value: tab.value })}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                onClick={() => handleTabChange(tab)}
                className={classNames(
                  "whitespace-nowrap cursor-pointer border-b-2 py-4 px-1 text-sm font-medium",
                  tab.value === curTab.value
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TabMenu;
