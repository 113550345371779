const Panel = (props) => {
  const { children } = props;

  return (
    <div className="mx-auto max-w-7xl w-full divide-y divide-slate-200 overflow-hidden rounded-lg shadow">
      {children}
    </div>
  );
};

export default Panel;
