import ActionItems from "../components/Dashboard/ActionItems";
import MyAppointments from "../components/Appointments/MyAppointments";
import ScreeningTools from "../components/Dashboard/ScreeningTools";
import MyGoals from "../components/HealthGoals/MyGoals";

const DashboardPage = () => {
  return (
    <div className="mx-auto max-w-7xl w-full space-y-5">
      <div>
        <MyAppointments />
      </div>
      <div>
        <MyGoals />
      </div>
      <div className="flex flex-col-reverse sm:flex-row gap-5">
        <ScreeningTools />
        <ActionItems />
      </div>
    </div>
  );
};

export default DashboardPage;
