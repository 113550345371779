import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../providers/AuthProvider";
import { ApiContext } from "../../providers/ApiProvider";

import useNotification from "../../hooks/useNotification";

import { isPhoneValid, isEmailValid, isDobValid } from "../../utils/validators";
import {
  buildDob,
  availableEthnicities,
  availableGenders,
  availablePronouns,
  availableSexes,
} from "../../utils/patientUtils";

import Input from "../ui/Input";
import LoadingSpinner from "../ui/LoadingSpinner";
import SelectBox from "../ui/SelectBox";
import SubmitButton from "../ui/SubmitButton";
import PhotoSection from "./PhotoSection";

const Account = () => {
  const auth = useContext(AuthContext);
  const api = useContext(ApiContext);

  const { show } = useNotification();

  const currentUser = auth.currentUser();

  const [isLoading, setIsLoading] = useState(true);
  const [photoFile, setPhotoFile] = useState(null);

  const [firstName, setFirstName] = useState({ value: currentUser?.first_name || "", error: false, touched: false });
  const [lastName, setLastName] = useState({ value: currentUser?.last_name || "", error: false, touched: false });
  const [photo, setPhoto] = useState(currentUser?.photo || null);
  const [preferredName, setPreferredName] = useState({
    value: currentUser?.preferred_name || "",
    error: false,
    touched: false,
  });
  const [sexAtBirth, setSexAtBirth] = useState({
    value: availableSexes[currentUser?.sex] || availableSexes[""],
    error: false,
    touched: false,
  });
  const [gender, setGender] = useState({
    value: availableGenders[currentUser?.gender] || availableGenders[""],
    error: false,
    touched: false,
  });
  const [dob, setDob] = useState({ value: buildDob(currentUser?.dob) || "", error: false, touched: false });
  const [email, setEmail] = useState({ value: currentUser?.email || "", error: false, touched: false });
  const [phone, setPhone] = useState({ value: currentUser?.phone || "", error: false, touched: false });
  const [address, setAddress] = useState({ value: currentUser?.address || "", error: false, touched: false });
  const [ethnicity, setEthnicity] = useState({
    value: availableEthnicities[currentUser?.ethnicity] || availableEthnicities[""],
    error: false,
    touched: false,
  });
  const [pronouns, setPronouns] = useState({
    value: availablePronouns[currentUser?.pronouns] || availablePronouns[""],
    error: false,
    touched: false,
  });

  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    api.client
      .get(`/patients/${currentUser.id}`)
      .then((resp) => {
        updatePatientInfo(resp.data.patient);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, currentUser.id]);

  useEffect(() => {
    if (!firstName.touched) return;

    setFirstName((prev) => {
      return {
        ...prev,
        error: firstName.value.trim().length < 1,
      };
    });
  }, [firstName.touched, firstName.value]);

  useEffect(() => {
    if (!lastName.touched) return;

    setLastName((prev) => {
      return {
        ...prev,
        error: lastName.value.trim().length < 1,
      };
    });
  }, [lastName.touched, lastName.value]);

  useEffect(() => {
    if (!dob.touched) return;

    setDob((prev) => {
      return {
        ...prev,
        error: !isDobValid(dob.value),
      };
    });
  }, [dob.touched, dob.value]);

  useEffect(() => {
    if (!email.touched) return;

    setEmail((prev) => {
      return {
        ...prev,
        error: !isEmailValid(email.value),
      };
    });
  }, [email.touched, email.value]);

  useEffect(() => {
    if (!phone.touched) return;

    setPhone((prev) => {
      return {
        ...prev,
        error: phone.value.length > 0 && !isPhoneValid(phone.value),
      };
    });
  }, [phone.touched, phone.value]);

  useEffect(() => {
    setIsFormValid(!firstName.error && !lastName.error && !dob.error && !email.error && !phone.error);
  }, [dob.error, email.error, phone.error, firstName.error, lastName.error]);

  const updatePatientInfo = (patient) => {
    setAddress((prev) => ({ ...prev, value: patient.address || "" }));
    setDob((prev) => ({ ...prev, value: buildDob(patient.dob) || "" }));
    setEmail((prev) => ({ ...prev, value: patient.email || "" }));
    setEthnicity((prev) => ({ ...prev, value: availableEthnicities[patient.ethnicity] || availableEthnicities[""] }));
    setGender((prev) => ({ ...prev, value: availableGenders[patient.gender] || availableGenders[""] }));
    setFirstName((prev) => ({ ...prev, value: patient.first_name || "" }));
    setLastName((prev) => ({ ...prev, value: patient.last_name || "" }));
    setPhone((prev) => ({ ...prev, value: patient.phone || "" }));
    setPhoto(patient.photo || "");
    setPreferredName((prev) => ({ ...prev, value: patient.preferred_name || "" }));
    setSexAtBirth((prev) => ({ ...prev, value: availableSexes[patient.sex] || availableSexes[""] }));
    setPronouns((prev) => ({ ...prev, value: availablePronouns[patient.pronouns] || availablePronouns[""] }));
  };

  const handleSubmit = () => {
    const data = {
      first_name: firstName.value,
      last_name: lastName.value,
      sex: sexAtBirth.value.value,
      dob: dob.value,
      email: email.value,
      phone: phone.value,
      address: address.value,
      ethnicity: ethnicity.value.value,
      gender: gender.value.value,
      preferred_name: preferredName.value,
      photo: photoFile,
      pronouns: pronouns.value.value,
    };

    setIsLoading(true);
    api.client
      .put(
        `/patients/${currentUser.id}`,
        { patient: data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((resp) => {
        updatePatientInfo(resp.data.patient);
        setIsLoading(false);
        show("Patient details updated.", "", false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="">
          <div className="h-24 bg-gray-700 sm:h-20 lg:h-28" />
          <PhotoSection photo={photo} alt={`${firstName} ${lastName}`} onChange={setPhotoFile} />
          <div className="sm:mx-5 my-5 space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 sm:space-y-5">
              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div className="space-y-2">
                  <div className="grid md:grid-cols-2 gap-3">
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                      value={firstName.value}
                      isValid={!firstName.error}
                      onChange={(val) => setFirstName((prev) => ({ ...prev, value: val, touched: true }))}
                      onBlur={() => setFirstName((prev) => ({ ...prev, touched: true }))}
                      errorMessage="First name cannot be blank!"
                    />

                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                      value={lastName.value}
                      isValid={!lastName.error}
                      onChange={(val) => setLastName((prev) => ({ ...prev, value: val, touched: true }))}
                      onBlur={() => setLastName((prev) => ({ ...prev, touched: true }))}
                      errorMessage="Last name cannot be blank!"
                    />

                    <Input
                      type="email"
                      name="email"
                      placeholder="patient@email.com"
                      label="Email"
                      value={email.value}
                      required={true}
                      isValid={!email.error}
                      onChange={(val) => setEmail((prev) => ({ ...prev, value: val, touched: true }))}
                      onBlur={() => setEmail((prev) => ({ ...prev, touched: true }))}
                      errorMessage="Email is required!"
                    />

                    <Input
                      type="text"
                      name="preferredName"
                      placeholder="Patient's Preferred Name"
                      label="Preferred Name"
                      value={preferredName.value}
                      isValid={true}
                      onChange={(val) => setPreferredName((prev) => ({ ...prev, value: val, touched: true }))}
                      onBlur={() => setPreferredName((prev) => ({ ...prev, touched: true }))}
                      errorMessage=""
                    />

                    <Input
                      type="date"
                      name="dob"
                      placeholder=""
                      label="Date of Birth"
                      value={dob.value}
                      isValid={!dob.error}
                      onChange={(val) => setDob((prev) => ({ ...prev, value: val, touched: true }))}
                      onBlur={() => setDob((prev) => ({ ...prev, touched: true }))}
                      errorMessage="Date of birth must be a valid date"
                    />
                  </div>

                  <hr className="h-px !my-5 bg-gray-300/80 border-0" />

                  <div className="grid md:grid-cols-2 gap-3">
                    <div>
                      <label htmlFor="sex" className="block text-sm font-medium text-gray-700 mb-1">
                        Sex at Birth
                      </label>
                      <div>
                        <SelectBox
                          activeValue={sexAtBirth.value}
                          values={Object.values(availableSexes)}
                          onChange={(val) => setSexAtBirth((prev) => ({ ...prev, value: val, touched: true }))}
                          onBlur={() => setSexAtBirth((prev) => ({ ...prev, touched: true }))}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="sex" className="block text-sm font-medium text-gray-700 mb-1">
                        Gender
                      </label>
                      <div>
                        <SelectBox
                          activeValue={gender.value}
                          values={Object.values(availableGenders)}
                          onChange={(val) => setGender((prev) => ({ ...prev, value: val, touched: true }))}
                          onBlur={() => setGender((prev) => ({ ...prev, touched: true }))}
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="sex" className="block text-sm font-medium text-gray-700 mb-1">
                        Gender
                      </label>
                      <div>
                        <SelectBox
                          activeValue={pronouns.value}
                          values={Object.values(availablePronouns)}
                          onChange={(val) => setPronouns((prev) => ({ ...prev, value: val, touched: true }))}
                          onBlur={() => setPronouns((prev) => ({ ...prev, touched: true }))}
                        />
                      </div>
                    </div>
                  </div>

                  <hr className="h-px !my-5 bg-gray-300/80 border-0" />

                  <div className="grid md:grid-cols-2 gap-3">
                    <Input
                      type="tel"
                      name="phone"
                      placeholder="555-555-5555"
                      label="Phone"
                      value={phone.value}
                      isValid={!phone.error}
                      onChange={(val) => setPhone((prev) => ({ ...prev, value: val, touched: true }))}
                      onBlur={() => setPhone((prev) => ({ ...prev, touched: true }))}
                      errorMessage="Phone format is incorrect! Ex. 555-555-5555"
                    />

                    <Input
                      type="text"
                      name="address"
                      placeholder="123 Main St. Your Town, CT 010101"
                      label="Address"
                      value={address.value}
                      isValid={true}
                      onChange={(val) => setAddress((prev) => ({ ...prev, value: val, touched: true }))}
                      onBlur={() => setAddress((prev) => ({ ...prev, touched: true }))}
                      errorMessage=""
                    />
                  </div>

                  <hr className="h-px !my-5 bg-gray-300/80 border-0" />

                  <div className="grid md:grid-cols-2 gap-3">
                    <div>
                      <label htmlFor="ethnicity" className="block text-sm font-medium text-gray-700 mb-1">
                        Ethnicity
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <SelectBox
                          activeValue={ethnicity.value}
                          values={Object.values(availableEthnicities)}
                          onChange={(val) => setEthnicity((prev) => ({ ...prev, value: val, touched: true }))}
                          onBlur={() => setEthnicity((prev) => ({ ...prev, touched: true }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-5">
            <SubmitButton onSubmit={handleSubmit} isValid={isFormValid} />
          </div>
        </div>
      )}
    </>
  );
};

export default Account;
