import { useEffect, useState } from "react";

import ButtonGroup from "../ui/ButtonGroup";
import LoadingSpinner from "../ui/LoadingSpinner";
import SelectBox from "../ui/SelectBox";

const SingleOption = (props) => {
  const { answer, question, onChange, isLoading } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!!question.multi_options) {
      setOptions(question.multi_options.sort((a, b) => a.position - b.position));
    } else {
      setOptions(question.options.sort((a, b) => a.position - b.position));
    }
  }, [question]);

  const handleSave = (val) => {
    if (!val) {
      onChange("");
    } else {
      onChange(JSON.stringify([val.id]));
    }
  };

  const buildValues = () => {
    return [{ id: -1, content: "Please select option", value: null }, ...options];
  };

  const buttonView = () => {
    return <ButtonGroup onChange={handleSave} options={options} answer={answer} />;
  };

  const selectView = () => {
    return (
      <SelectBox
        // activeValue={answer?.multi_options[0] || { id: -1, content: "Please select option", value: "" }}
        activeValue={{ id: -1, content: "Please select option", value: "" }}
        values={buildValues()}
        onChange={handleSave}
      />
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="w-full sm:hidden">{options.length > 2 ? selectView() : buttonView()}</div>
          <div className="w-full hidden sm:inline">{buttonView()}</div>
        </>
      )}
    </>
  );
};

export default SingleOption;
