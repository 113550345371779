import { useEffect, useState } from "react";

import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

import { classNames } from "../../utils/cssUtils";
import { forwardRef } from "react";

const PasswordInput = forwardRef(function Input(props, ref) {
  const {
    errorMessage,
    label,
    onChange,
    onBlur,
    autoComplete = "current-password",
    isValid = true,
    name = "password",
    placeholder = "Password",
  } = props;

  const [visible, setVisible] = useState(false);
  const [inputType, setInputType] = useState("password");

  useEffect(() => {
    if (visible) {
      setInputType("text");
    } else {
      setInputType("password");
    }
  }, [visible]);

  const handleChange = (e) => {
    e.preventDefault();

    if (!!onChange && typeof onChange === "function") {
      onChange(e.target.value);
    }
  };

  const handleBlur = (e) => {
    e.preventDefault();

    if (!!onBlur && typeof onBlur === "function") {
      onBlur(e.target.value);
    }
  };

  return (
    <div>
      {label && (
        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="relative mt-2 rounded-md shadow-sm">
        {!isValid && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
        <input
          ref={ref}
          type={inputType}
          name={name}
          id={name}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onChange={handleChange}
          onBlur={handleBlur}
          className={classNames(
            "block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset shadow-sm sm:text-sm sm:leading-6",
            !isValid
              ? "pl-10 border-red-300 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
              : "border-gray-300 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 focus:border-indigo-500"
          )}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          {visible ? (
            <EyeSlashIcon
              onClick={() => setVisible((prev) => !prev)}
              className="cursor-pointer h-5 w-5 text-slate-600"
              aria-hidden="true"
            />
          ) : (
            <EyeIcon
              onClick={() => setVisible((prev) => !prev)}
              className="cursor-pointer h-5 w-5 text-slate-600"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      {!isValid && (
        <p className="mt-2 flex justify-left sm:text-sm text-red-600" id="result-error">
          {errorMessage}
        </p>
      )}
    </div>
  );
});

export default PasswordInput;
