import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  parse,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from "date-fns";

import { classNames } from "../../utils/cssUtils";

const SmallMonth = (props) => {
  const { selectedDay, onNextMonth, onPrevMonth, onDateSelect, availableDates } = props;

  let today = startOfToday();
  let firstDayOfCurrentMonth = startOfMonth(selectedDay);
  let days = eachDayOfInterval({
    start: startOfWeek(firstDayOfCurrentMonth),
    end: endOfWeek(endOfMonth(firstDayOfCurrentMonth)),
  }).map((date) => ({
    date,
  }));

  const availability = availableDates.reduce((acc, date) => {
    const key = parse(date, "yyyy-MM-dd", new Date());
    return { ...acc, [key]: true };
  }, {});

  return (
    <>
      <div className="flex items-center text-center text-slate-900">
        <button
          type="button"
          onClick={onPrevMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-slate-400 hover:text-slate-500"
        >
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">{format(selectedDay, "MMMM yyyy")}</div>
        <button
          type="button"
          onClick={onNextMonth}
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-slate-400 hover:text-slate-500"
        >
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-slate-500">
        <div>S</div>
        <div>M</div>
        <div>T</div>
        <div>W</div>
        <div>T</div>
        <div>F</div>
        <div>S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-slate-200 text-sm shadow ring-1 ring-slate-200">
        {days.map((day, dayIdx) => (
          <button
            key={dayIdx}
            type="button"
            onClick={() => onDateSelect(day.date)}
            className={classNames(
              "py-1.5 hover:bg-slate-100 focus:z-10",
              isSameMonth(selectedDay, day.date) ? "bg-white" : "bg-slate-50",
              (isSameDay(day.date, selectedDay) || isSameDay(day.date, today)) && "font-semibold",
              isSameDay(day.date, selectedDay) && "text-white",
              !isSameDay(day.date, selectedDay) &&
                isSameMonth(day.date, today) &&
                !isSameDay(day.date, today) &&
                "text-slate-900",
              !isSameDay(day.date, selectedDay) &&
                !isSameMonth(day.date, today) &&
                !isSameDay(day.date, today) &&
                "text-slate-400",
              isSameDay(day.date, today) && !isSameDay(day.date, selectedDay) && "text-indigo-600",
              dayIdx === 0 && "rounded-tl-lg",
              dayIdx === 6 && "rounded-tr-lg",
              dayIdx === days.length - 7 && "rounded-bl-lg",
              dayIdx === days.length - 1 && "rounded-br-lg"
            )}
          >
            <time
              dateTime={day.date}
              className={classNames(
                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                isSameDay(day.date, selectedDay) && isSameDay(day.date, today) && "bg-indigo-600",
                isSameDay(day.date, selectedDay) && !isSameDay(day.date, today) && "bg-slate-900"
              )}
            >
              {format(day.date, "d")}
            </time>
            <div className="w-1 h-1 mx-auto mt-1">
              {!!availability[day.date] && <div className="w-1 h-1 rounded-full bg-indigo-500"></div>}
            </div>
          </button>
        ))}
      </div>
    </>
  );
};

export default SmallMonth;
