import { useContext, useEffect, useState } from "react";

import { ApiContext } from "../../providers/ApiProvider";

import useNotification from "../../hooks/useNotification";

import LoadingSpinner from "../ui/LoadingSpinner";
import PasswordInput from "../ui/PasswordInput";
import SubmitButton from "../ui/SubmitButton";

const Password = () => {
  const api = useContext(ApiContext);

  const { show } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState({ value: "", error: false, touched: false });
  const [currentPassword, setCurrentPassword] = useState({ value: "", error: false, touched: false });
  const [newPassword, setNewPassword] = useState({ value: "", error: false, touched: false });

  const [isFormValid, setIsFormValid] = useState(true);

  useEffect(() => {
    if (!currentPassword.touched) return;

    setNewPassword((prev) => {
      return {
        ...prev,
        error: currentPassword.value.trim().length < 6,
      };
    });
  }, [currentPassword.touched, currentPassword.value]);

  useEffect(() => {
    if (!newPassword.touched) return;

    setNewPassword((prev) => {
      return {
        ...prev,
        error: newPassword.value.trim().length < 6 || confirmPassword.value !== newPassword.value,
      };
    });
  }, [confirmPassword.value, newPassword.value, newPassword.touched]);

  useEffect(() => {
    if (!confirmPassword.touched) return;

    setConfirmPassword((prev) => {
      return {
        ...prev,
        error: confirmPassword.value.trim().length < 6 || confirmPassword.value !== newPassword.value,
      };
    });
  }, [confirmPassword.value, confirmPassword.touched, newPassword.value]);

  useEffect(() => {
    setIsFormValid(!confirmPassword.error && !currentPassword.error && !newPassword.error);
  }, [confirmPassword.error, currentPassword.error, newPassword.error]);

  const handleUpdate = () => {
    const cur = currentPassword;
    const newPwd = newPassword;
    const confPwd = confirmPassword;

    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");

    setIsLoading(true);
    api.client
      .put("/auth/password", { current_password: cur, password: newPwd, password_confirmation: confPwd })
      .then((resp) => {
        setIsLoading(false);
        show("Your password has been updated.", "", false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="mt-10 divide-y divide-gray-200">
            <div className="mt-6 flex flex-col gap-1.5 md:gap-3">
              <PasswordInput
                name="currentPassword"
                placeholder="Current Password"
                value={currentPassword.value}
                isValid={!currentPassword.error}
                onChange={(val) => setCurrentPassword((prev) => ({ ...prev, value: val }))}
                onBlur={() => setCurrentPassword((prev) => ({ ...prev, touched: true }))}
                errorMessage="Please enter current current password!"
              />

              <PasswordInput
                name="newPassword"
                placeholder="Enter new Password"
                value={newPassword.value}
                isValid={!newPassword.error}
                onChange={(val) => setNewPassword((prev) => ({ ...prev, value: val }))}
                onBlur={() => setNewPassword((prev) => ({ ...prev, touched: true }))}
                errorMessage="Password has to be at least 6 characters and must match confirmation password!"
              />

              <PasswordInput
                name="confirmPassword"
                placeholder="Confirm new Password"
                value={confirmPassword.value}
                isValid={!confirmPassword.error}
                onChange={(val) => setConfirmPassword((prev) => ({ ...prev, value: val }))}
                onBlur={() => setConfirmPassword((prev) => ({ ...prev, touched: true }))}
                errorMessage="Password has to be at least 6 characters and must match confirmation password!"
              />
            </div>
          </div>
          <div className="pt-5">
            <div className="justify-end w-full">
              <SubmitButton onSubmit={handleUpdate} isValid={isFormValid} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Password;
