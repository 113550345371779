/* eslint-disable jsx-a11y/no-redundant-roles */
const StackedList = (props) => {
  const { items, renderFunc, onClick } = props;

  return (
    <ul
      role="list"
      className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
    >
      {items.map((item) => (
        <li
          key={item.id}
          onClick={() => onClick(item)}
          className="relative cursor-pointer flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
        >
          {renderFunc(item)}
        </li>
      ))}
    </ul>
  );
};

export default StackedList;
