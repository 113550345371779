import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { PencilSquareIcon } from "@heroicons/react/24/outline";

import { ApiContext } from "../../providers/ApiProvider";

import { classNames } from "../../utils/cssUtils";

import LoadingSpinner from "../ui/LoadingSpinner";
import Panel from "../ui/Panel/Panel";
import PanelBody from "../ui/Panel/PanelBody";
import PanelHeader from "../ui/Panel/PanelHeader";
import Table from "../ui/Table";
import TabMenu from "../ui/TabMenu";

const ScreeningTools = () => {
  const api = useContext(ApiContext);

  const navigate = useNavigate();

  const tableHeader = ["name", "status", "due"];

  const tabs = {
    inProgress: { name: "In Progress Items", value: 0 },
    completed: { name: "Completed Items", value: 1 },
  };

  const [curTab, setCurTab] = useState(tabs.inProgress);
  const [inProgressTools, setInProgressTools] = useState([]);
  const [completedTools, setCompletedTools] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api.client
      .get("/screening_tools")
      .then((resp) => {
        const psts = resp.data.patient_screening_tools;

        if (psts && psts.length > 0) {
          psts.forEach((pst) => {
            if (pst.status === "completed") {
              setCompletedTools((prev) => [...prev, pst]);
            } else {
              setInProgressTools((prev) => [...prev, pst]);
            }
          });
        }

        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client]);

  const handleToolView = (tool) => {
    navigate(`/screening-tools/${tool.id}`);
  };

  const calcProgress = (tool) => {
    const progress = Number(tool.progress);

    if (isNaN(progress)) return 0;

    return progress >= 100 ? 100 : progress;
  };

  const statusBarColor = (tool) => {
    return calcProgress(tool) >= 100 ? "bg-green-600" : "bg-indigo-600";
  };

  const handleChangeTab = (tab) => {
    setCurTab(tab);
  };

  const buildRow = (tool) => {
    return (
      <>
        <td className="py-4 text-left pl-3 sm:pl-4 flex items-center">
          <div className="flex gap-x-3">
            <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            {tool.screening_tool.name}
          </div>
        </td>
        <td className="hidden sm:table-cell text-center font-medium">
          <div className="w-full bg-gray-200 rounded-full h-2 sm:h-2.5">
            <div
              className={classNames(statusBarColor(tool), "h-2 sm:h-2.5 rounded-full")}
              style={{ width: `${calcProgress(tool)}%` }}
            ></div>
          </div>
        </td>
        <td className="hidden sm:table-cell text-center font-medium">{tool.due_by}</td>
      </>
    );
  };

  return (
    <>
      <Panel>
        <PanelHeader>
          <TabMenu tabs={Object.values(tabs)} curTab={curTab} onChange={handleChangeTab} />
        </PanelHeader>
        <PanelBody>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Table
              headers={tableHeader}
              mobileVisibleColumns={[0]}
              items={curTab.value === tabs.inProgress.value ? inProgressTools : completedTools}
              buildRow={buildRow}
              onClick={handleToolView}
            />
          )}
        </PanelBody>
        <div className="bg-gray-50 px-4 py-2 sm:px-6">
          <span className="text-sm text-slate-600">* status bar updates upon refresh</span>
        </div>
      </Panel>
    </>
  );
};

export default ScreeningTools;
