import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";

const TextQuestion = (props) => {
  const { onChange, rows = 4, useSaveButton = false } = props;

  const [answer, setAnswer] = useState(props.answer || { value: "" });
  const [firstRender, setFirstRender] = useState(true);

  const debounce = useDebounce(answer, 500);

  useEffect(() => {
    if (firstRender || answer === props.answer) {
      setFirstRender(false);
      return;
    }

    if (debounce && !useSaveButton) {
      onChange(debounce.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, useSaveButton]);

  const handleSave = () => {
    onChange(answer.value);
  };

  return (
    <div className="w-full">
      <textarea
        rows={rows}
        name="comment"
        id="comment"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        onChange={(e) => setAnswer({ value: e.target.value })}
        value={answer.value || ""}
      />
      {useSaveButton && (
        <button
          type="button"
          onClick={handleSave}
          className="w-full mt-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      )}
    </div>
  );
};

export default TextQuestion;
