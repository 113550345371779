import { createContext, useCallback, useState  } from "react";

const NotificationContext = createContext({
  message: "",
  subMessage: "",
  visible: false,
  show: (message, subMessage, isError) => {},
  hide: () => {},
});

const NotificationProvider = ({children}) => {
  const [message, setMessage] = useState(null);
  const [visible, setVisible] = useState(false);
  const [subMessage, setSubMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const show = (message, subMessage, isError) => {
    setMessage(message);
    setSubMessage(subMessage);
    setIsError(isError);
    setVisible(true);

    setTimeout(() => {
      setVisible(false);
    }, 10000);
  };

  const hide = () => {
    setMessage(null);
    setSubMessage(null);
    setIsError(false);
    setVisible(false);
  };

  const contextValue = {
    message,
    subMessage,
    visible,
    isError,
    show: useCallback((message, subMessage, isError) => show(message, subMessage, isError), []),
    hide: useCallback(() => hide(), []),
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
}; 

export { NotificationContext, NotificationProvider};