import { useContext, useState } from "react";

import { ChatBubbleBottomCenterTextIcon, CalendarIcon } from "@heroicons/react/24/outline";

import LoadingSpinner from "../ui/LoadingSpinner";

import CancelAppointment from "./CancelAppointment";
import RescheduleAppointment from "./RescheduleAppointment";
import { ApiContext } from "../../providers/ApiProvider";

const AppointmentRow = (props) => {
  const { appointment, onCancel, onReschedule } = props;

  const api = useContext(ApiContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);

  const handleSubmitCancel = (note) => {
    setIsLoading(true);

    api.client
      .delete(`/appointments/${appointment.id}`, { data: { note } })
      .then((resp) => {
        setShowCancel(false);
        setIsLoading(false);
        onCancel(appointment);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  const handleReschedule = (datetime) => {
    setIsLoading(true);

    api.client
      .put(`/appointments/${appointment.id}`, { datetime })
      .then((resp) => {
        setShowReschedule(false);
        setIsLoading(false);
        onReschedule(resp.data);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };

  return (
    <li key={appointment.id}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="relative flex space-x-6 py-3 ring-1 ring-slate-200 rounded-lg px-5 items-center lg:w-full bg-white shadow-sm hover:shadow-md transition duration-150 ease-in-out">
            <div>
              <img src={appointment.physician?.photo_url} alt="" className="h-14 w-14 flex-none rounded-full" />
            </div>
            <dl className="flex flex-col w-full text-slate-500 xl:flex-row items-center">
              <div className="flex items-start space-x-3">
                <dd>
                  {appointment.physician?.first_name} {appointment.physician?.last_name}
                </dd>
              </div>
              <div className="flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt className="">
                  <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <time dateTime={appointment.datetime}>
                    {appointment.date} at {appointment.time}
                  </time>
                </dd>
              </div>
              <div className="flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                <dt className="">
                  <ChatBubbleBottomCenterTextIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>{appointment.type}</dd>
              </div>
              {!showReschedule && !showCancel && (
                <div className="flex justify-items-end xl:ml-3.5 xl:mt-0 xl:pl-3.5">
                  <dd className="flex space-x-5">
                    <button
                      type="button"
                      onClick={() => setShowCancel(true)}
                      className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-900 shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-50 hover:shadow-md transition duration-150 ease-in-out"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowReschedule(true)}
                      className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-50 hover:shadow-md transition duration-150 ease-in-out"
                    >
                      Reschedule
                    </button>
                  </dd>
                </div>
              )}
            </dl>
          </div>
          {showCancel && (
            <div>
              <CancelAppointment onCancel={() => setShowCancel(false)} onSubmit={handleSubmitCancel} />
            </div>
          )}
          {showReschedule && (
            <div>
              <RescheduleAppointment
                appointment={appointment}
                onClose={() => setShowReschedule(false)}
                onReschedule={handleReschedule}
              />
            </div>
          )}
        </>
      )}
    </li>
  );
};

export default AppointmentRow;
