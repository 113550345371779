import { CheckCircleIcon } from "@heroicons/react/20/solid";

import { classNames } from "../../utils/cssUtils";

function getGridColsClass(options) {
  switch (options?.length) {
    case 1:
      return "";
    case 2:
      return "sm:grid-cols-2";
    case 3:
      return "md:grid-cols-3";
    case 4:
      return "md:grid-cols-2 lg:grid-cols-4";
    case 5:
      return "md:grid-cols-3 lg:grid-cols-5";
    default:
      return "sm:grid-cols-2 lg:grid-cols-3";
  }
}

const Button = (props) => {
  const { opt, checked, onClick } = props;

  return (
    <div
      onClick={onClick}
      className={classNames(
        checked ? "border-transparent bg-indigo-100" : "bg-slate-50 border-gray-300 hover:bg-indigo-100",
        "relative flex cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none"
      )}
    >
      <span className="flex flex-1">
        <span className="flex flex-col">
          <span className="block text-sm font-medium text-gray-900">{opt.content}</span>
        </span>
      </span>
      <CheckCircleIcon
        className={classNames(!checked ? "invisible" : "", "h-5 w-5 text-indigo-700")}
        aria-hidden="true"
      />
      <span
        className={classNames(
          checked ? "border-indigo-600" : "border-transparent",
          "border-2 pointer-events-none absolute -inset-px rounded-lg"
        )}
        aria-hidden="true"
      />
    </div>
  );
};

const ButtonGroup = (props) => {
  const { options, answer, onChange } = props;

  const checked = (opt) => {
    if (!answer) return false;

    if (!!answer.multi_options) {
      return opt.id === answer?.multi_options[0]?.id;
    } else {
      return opt.id === answer?.options[0]?.id;
    }
  };

  const handleClick = (e, opt) => {
    e.preventDefault();

    let result = null;

    if (!answer) {
      onChange(opt);
      return;
    }

    if (!!answer.multi_options) {
      result = opt.id === answer?.multi_options[0]?.id ? null : opt;
    } else {
      result = opt.id === answer?.options[0]?.id ? null : opt;
    }

    onChange(result);
  };

  return (
    <div className={classNames(getGridColsClass(options), "mt-4 grid grid-cols-1 gap-y-5 md:gap-y-6 gap-x-4")}>
      {options.map((opt) => (
        <Button onClick={(e) => handleClick(e, opt)} key={opt.id} opt={opt} checked={checked(opt)} />
      ))}
    </div>
  );
};

export default ButtonGroup;
