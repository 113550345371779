import { useContext, useState } from "react";

import { ApiContext } from "../../providers/ApiProvider";

import { classNames } from "../../utils/cssUtils";

import DateQuestion from "./DateQuestion";
import HeightQuestion from "./HeightQuestion";
import MultiQuestion from "./MultiQuestion";
import NumberQuestion from "./NumberQuestion";
import TextQuestion from "./TextQuestion";
import TimeQuestion from "./TimeQuestion";

const Question = (props) => {
  const api = useContext(ApiContext);

  const { toolId, useSaveButton, onAnswer } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState(props.question);
  const [isAnswered, setIsAnswered] = useState(props.question?.answer !== null || false);

  const handleAnswerChange = (data) => {
    setIsLoading(true);

    api.client
      .post(`/answers`, { question_id: question.id, value: data, tool_id: toolId })
      .then((resp) => {
        const qa = resp.data.questions_answer;
        setQuestion(qa);
        setIsAnswered(!!qa.answer);

        setIsLoading(false);

        onAnswer(resp.data.questions_answer, resp.data.meta?.questions);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  };
  //
  return (
    <>
      <div className={classNames("bg-white shadow sm:rounded-lg", isAnswered && "border-2 border-emerald-300")}>
        <div className="px-4 py-5 sm:p-6 justify-items-center justify-center place-content-center">
          <h3 className="text-base font-semibold leading-6 text-gray-900">{question.content}</h3>
          <div className="mt-3 flex flex-row items-center gap-x-5">
            {question.typeof === 2 &&
              (question.external_id === "Q103" ? (
                <HeightQuestion
                  question={question}
                  answer={question.answer}
                  onChange={handleAnswerChange}
                  isLoading={isLoading}
                />
              ) : (
                <NumberQuestion
                  question={question}
                  answer={question.answer}
                  onChange={handleAnswerChange}
                  isLoading={isLoading}
                  useSaveButton={useSaveButton}
                />
              ))}

            {question.typeof === 0 && (
              <MultiQuestion
                question={question}
                answer={question.answer}
                onChange={handleAnswerChange}
                isLoading={isLoading}
              />
            )}

            {question.typeof === 1 && (
              <TextQuestion
                question={question}
                answer={question.answer}
                onChange={handleAnswerChange}
                isLoading={isLoading}
                useSaveButton={useSaveButton}
              />
            )}

            {question.typeof === 3 && (
              <TimeQuestion
                question={question}
                answer={question.answer}
                onChange={handleAnswerChange}
                isLoading={isLoading}
              />
            )}

            {question.typeof === 4 && (
              <DateQuestion
                question={question}
                answer={question.answer}
                onChange={handleAnswerChange}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
