import { useContext, useEffect, useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import { ApiContext } from "../providers/ApiProvider";

import ErrorFlash from "../components/ui/ErrorFlash";
import PasswordInput from "../components/ui/PasswordInput";
import SubmitButton from "../components/ui/SubmitButton";
import LoadingSpinner from "../components/ui/LoadingSpinner";

const RegisterPage = () => {
  const api = useContext(ApiContext);

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();

  const [password, setPassword] = useState({ value: "", error: false, touched: false });
  const [confirmPassword, setConfirmPassword] = useState({ value: "", error: false, touched: false });
  const [showLoginError, setShowLoginError] = useState({ show: false, errors: [] });
  const [isFormValid, setIsFormValid] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(searchParams.get("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [monthlyNewsletter, setMonthlyNewsletter] = useState(false);
  const [caspiaUpdatesEmail, setCaspiaUpdatesEmail] = useState(false);

  useEffect(() => {
    if (!password.touched) return;

    setPassword((prev) => {
      return {
        ...prev,
        error: password.value.trim().length < 6 || confirmPassword.value !== password.value,
      };
    });
  }, [confirmPassword.value, password.value, password.touched]);

  useEffect(() => {
    if (!confirmPassword.touched) return;

    setConfirmPassword((prev) => {
      return {
        ...prev,
        error: confirmPassword.value.trim().length < 6 || confirmPassword.value !== password.value,
      };
    });
  }, [confirmPassword.value, confirmPassword.touched, password.value]);

  useEffect(() => {
    setIsFormValid(!confirmPassword.error && !password.error);
  }, [confirmPassword.error, password.error]);

  const handleRegister = () => {
    setIsLoading(true);
    api.client
      .post(`/registrations`, {
        token: token,
        password: password.value,
        password_confirmation: confirmPassword.value,
        monthly_newsletter: monthlyNewsletter,
        caspia_updates_email: caspiaUpdatesEmail,
      })
      .then((resp) => {
        navigate(`/login?email=${resp.data.patient.email}`);
      })
      .catch((resp) => {
        const errors = resp.errors || ["An error occurred. Please try again."];
        setIsLoading(false);
        setShowLoginError({ show: true, errors: errors });
      });
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Enter password to finish registration
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="max-w-4xl flex justify-center sm:w-full">
            <div className="w-full flex flex-col justify-center">
              <div className="w-full">{showLoginError.show && <ErrorFlash errors={showLoginError.errors} />}</div>
              <div className="w-full">
                <PasswordInput
                  name="Password"
                  placeholder="Enter new Password"
                  value={password.value}
                  isValid={!password.error}
                  onChange={(val) => setPassword((prev) => ({ ...prev, value: val }))}
                  onBlur={() => setPassword((prev) => ({ ...prev, touched: true }))}
                  errorMessage="Password has to be at least 6 characters and must match confirmation password!"
                />

                <PasswordInput
                  name="confirmPassword"
                  placeholder="Confirm new Password"
                  value={confirmPassword.value}
                  isValid={!confirmPassword.error}
                  onChange={(val) => setConfirmPassword((prev) => ({ ...prev, value: val }))}
                  onBlur={() => setConfirmPassword((prev) => ({ ...prev, touched: true }))}
                  errorMessage="Password has to be at least 6 characters and must match confirmation password!"
                />
              </div>
              <div className="mt-5 text-sm">
                By signing up, you agree to the
                <a href="/terms" className="mx-2 hover:underline text-indigo-800">
                  Terms of Service
                </a>
                and
                <a href="/privacy" className="mx-2 hover:underline text-indigo-800">
                  Privacy Statement
                </a>
              </div>
              <div className="mt-5 text-sm">
                We are committed to protecting your privacy. Please read our
                <a href="/hipaa" className="mx-2 hover:underline text-indigo-800">
                  HIPAA notice
                </a>
              </div>
              <div className="mt-5 text-sm">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="monthly-newsletter"
                      aria-describedby="monthly-newsletter-description"
                      name="monthly-newsletter"
                      type="checkbox"
                      checked={monthlyNewsletter}
                      onChange={() => setMonthlyNewsletter((prev) => !prev)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900">
                      Sign up for Healthy Today, our FREE monthly e-newsletter, with tips, tricks, recipe, and
                      suggestions to help you in your journey towards health.
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-sm">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="caspia-updates-email"
                      aria-describedby="caspia-updates-email-description"
                      name="caspia-updates-email"
                      type="checkbox"
                      checked={caspiaUpdatesEmail}
                      onChange={() => setCaspiaUpdatesEmail((prev) => !prev)}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="comments" className="font-medium text-gray-900">
                      Receive updates from Caspia Health when new features and integrations are available (e.g. MyChart,
                      AppleWatch, etc.)
                    </label>
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="justify-end w-full">
                  {isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <SubmitButton label="Register" onSubmit={handleRegister} isValid={isFormValid} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
