import MultipleOptions from "./MultiOptions";
import SingleOption from "./SingleOptions";

const MultiQuestion = (props) => {
  const { question, answer, onChange, isLoading } = props;

  return question.multiple ? (
    <MultipleOptions question={question} answer={answer} onChange={onChange} isLoading={isLoading} />
  ) : (
    <SingleOption question={question} answer={answer} onChange={onChange} isLoading={isLoading} />
  );
};

export default MultiQuestion;
