import { useContext } from "react";
import { NotificationContext } from "../providers/NotificationProvider";

const useNotification = () => {
  const { message, isError, subMessage, visible, show, hide } = useContext(NotificationContext);

  return { message, isError, subMessage, visible, show, hide };
};

export default useNotification;
