import { classNames } from "../../utils/cssUtils";

const Table = (props) => {
  const { headers, items, buildRow, onClick, mobileVisibleColumns } = props;

  const handleClick = (item) => {
    onClick && onClick(item);
  };

  const showHeader = (idx) => {
    return mobileVisibleColumns && mobileVisibleColumns.includes(idx);
  };

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            {headers.map((heading, idx) => (
              <th
                key={heading}
                scope="col"
                className={classNames(
                  "capitalize py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6",
                  showHeader(idx) ? "table-cell" : "hidden sm:table-cell",
                  idx === 0 ? "text-left" : "text-center"
                )}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white">
          {items.map((item, idx) => (
            <tr
              key={item.id}
              onClick={() => handleClick(item)}
              className={classNames(
                "text-sm sm:text-base hover:bg-slate-200 group",
                idx % 2 === 0 ? undefined : "bg-gray-50 border-b border-gray-200",
                onClick ? "cursor-pointer" : ""
              )}
            >
              {buildRow(item)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
