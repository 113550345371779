import { useContext, useEffect, useRef, useState } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

import { ApiContext } from "../providers/ApiProvider";

import { classNames } from "../utils/cssUtils";

import LoadingSpinner from "../components/ui/LoadingSpinner";

import Panel from "../components/ui/Panel/Panel";
import PanelHeader from "../components/ui/Panel/PanelHeader";
import PanelBody from "../components/ui/Panel/PanelBody";
import Question from "../components/ScreeningTools/Question";

const ScreeningToolDetailsPage = () => {
  const api = useContext(ApiContext);

  const navigate = useNavigate();

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [answer] = useState(searchParams.get("option"));
  const [questionId] = useState(parseInt(searchParams.get("question")) || null);

  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [tool, setTool] = useState({});
  const [progress, setProgress] = useState(0);
  const [totalAnswers, setTotalAnswers] = useState(0);

  const questionRefs = useRef({});

  useEffect(() => {
    if (!questionId || !!answer) return;

    setSearchParams({});

    questionRefs.current[questionId]?.scrollIntoView({ behavior: "smooth" });
  }, [answer, questionId, setSearchParams]);

  useEffect(() => {
    if (!questionId || !answer) return;

    setIsLoading(true);
    api.client
      .post("/answers", {
        question_id: questionId,
        value: answer,
      })
      .then((resp) => {
        setSearchParams({});

        setQuestions((prev) => {
          const res = prev.map((q) => {
            if (q.id === questionId) {
              return resp.data.questions_answer;
            }

            return q;
          });

          return [...res];
        });

        setIsLoading(false);
        questionRefs.current[questionId]?.scrollIntoView({ behavior: "smooth" });
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [questionId, answer, api.client, setSearchParams]);

  useEffect(() => {
    if (isLoading || !questionId || !answer) return;

    questionRefs.current[questionId]?.scrollIntoView({ behavior: "smooth" });
  }, [isLoading, questionId, answer]);

  useEffect(() => {
    setTotalAnswers(questions?.filter((q) => !!q.answer).length || 0);
  }, [questions]);

  useEffect(() => {
    if (!questions || questions.length === 0) return;

    const pr = (totalAnswers / questions.length) * 100;

    if (isNaN(pr)) return 0;

    setProgress(pr >= 100 ? 100 : pr);
  }, [questions, totalAnswers]);

  useEffect(() => {
    api.client
      .get(`/screening_tools/${id}`)
      .then((resp) => {
        const t = resp.data.patient_screening_tool;
        setTool(t);

        setQuestions(t.questions_answers);

        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client, id]);

  const statusBarColor = () => {
    return progress >= 100 ? "bg-green-600" : "bg-indigo-600";
  };

  const handleAnswer = (question, allQuestions) => {
    if (question.answer) {
      setTotalAnswers(totalAnswers + 1);
    } else {
      setTotalAnswers(totalAnswers - 1);
    }

    if (!!allQuestions) {
      setQuestions(allQuestions);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="px-5">
          <Panel>
            <>
              <PanelHeader sticky={true}>
                <div className="flex justify-start items-start">
                  <h1 className="w-full text-2xl font-medium flex items-center flex-col sm:flex-row">
                    <div className="w-full flex items-center">
                      <div>
                        <ArrowLeftCircleIcon
                          onClick={() => navigate(-1)}
                          className="h-14 w-14 inline-block text-slate-600 pr-3 cursor-pointer hover:text-indigo-500"
                        />
                      </div>
                      <div className="w-full">{tool.screening_tool?.name}</div>
                    </div>
                    <div className="w-full flex justify-end mt-2">
                      <div className="w-full sm:w-64 bg-gray-200 rounded-full h-2 sm:h-2.5">
                        <div
                          className={classNames(statusBarColor(), "h-2 sm:h-2.5 rounded-full")}
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                    </div>
                  </h1>
                </div>
              </PanelHeader>
              <PanelBody>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="grid grid-row space-y-3 w-full">
                    {questions.map((question) => (
                      <div
                        key={question.id}
                        style={{ scrollMargin: "100px" }}
                        ref={(el) => (questionRefs.current[question.id] = el)}
                      >
                        <Question toolId={tool.id} question={question} onAnswer={handleAnswer} />
                      </div>
                    ))}
                  </div>
                )}
              </PanelBody>
            </>
          </Panel>
        </div>
      )}
    </>
  );
};

export default ScreeningToolDetailsPage;
