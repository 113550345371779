import { useRef } from "react";
import Input from "../ui/Input";
import LoadingSpinner from "../ui/LoadingSpinner";

const HeightQuestion = (props) => {
  const { answer, onChange, isLoading } = props;

  const feetRef = useRef();
  const inchRef = useRef();

  const handleSave = () => {
    if (feetRef.current.value !== "" && inchRef.current.value !== "") {
      onChange(Number.parseInt(feetRef.current.value) * 12 + Number.parseInt(inchRef.current.value));
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="w-full flex flex-col sm:flex-row gap-5">
          <div className="w-full">
            <Input
              ref={feetRef}
              type="number"
              name="feet"
              placeholder="Feet"
              defaultValue={Math.floor(answer?.value / 12) || ""}
              postFix="ft"
              isValid={true}
              onChange={handleSave}
            />
          </div>
          <div className="w-full">
            <Input
              ref={inchRef}
              type="number"
              name="inches"
              placeholder="Inches"
              defaultValue={!!answer ? answer.value % 12 : ""}
              postFix="in"
              isValid={true}
              onChange={handleSave}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default HeightQuestion;
