import { useEffect, useState } from "react";
import Input from "../ui/Input";
import LoadingSpinner from "../ui/LoadingSpinner";
import { useDebounce } from "@uidotdev/usehooks";

const NumberQuestion = (props) => {
  const { question, onChange, isLoading, useSaveButton = false } = props;

  const [answer, setAnswer] = useState(props.answer || { value: "" });
  const [firstRender, setFirstRender] = useState(true);

  const debounce = useDebounce(answer, 500);

  useEffect(() => {
    if (firstRender || answer === props.answer) {
      setFirstRender(false);
      return;
    }

    if (debounce && !useSaveButton) {
      onChange(debounce.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce, useSaveButton]);

  const handleSave = () => {
    onChange(answer.value);
  };

  const postfix = question.units === "times" ? "" : question.units;

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="w-full">
          <Input
            type="number"
            name={question.id}
            value={answer.value}
            postFix={postfix}
            isValid={true}
            onChange={(val) => setAnswer({ value: val })}
          />
          {useSaveButton && (
            <button
              type="button"
              onClick={handleSave}
              className="w-full mt-3 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default NumberQuestion;
