import { useEffect, useState } from "react";

import { useDebounce } from "@uidotdev/usehooks";

import Input from "../ui/Input";

const TimeQuestion = (props) => {
  const { question, onChange } = props;

  const [answer, setAnswer] = useState(props.answer || { value: "" });
  const [firstRender, setFirstRender] = useState(true);

  const debounce = useDebounce(answer, 500);

  useEffect(() => {
    if (firstRender || answer === props.answer) {
      setFirstRender(false);
      return;
    }

    if (debounce) {
      onChange(debounce.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  return (
    <div className="w-full">
      <Input
        type="time"
        name={question.id}
        value={answer.value}
        isValid={true}
        onChange={(val) => setAnswer({ value: val })}
      />
    </div>
  );
};

export default TimeQuestion;
