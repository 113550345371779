import { useContext, useEffect, useState } from "react";

import { compareAsc, endOfMonth, startOfMonth } from "date-fns";

import { ApiContext } from "../../providers/ApiProvider";

import LoadingSpinner from "../ui/LoadingSpinner";
import Panel from "../ui/Panel/Panel";
import PanelBody from "../ui/Panel/PanelBody";
import PanelHeader from "../ui/Panel/PanelHeader";
import AppointmentRow from "./AppointmentRow";

const MyAppointments = (props) => {
  const api = useContext(ApiContext);

  const [appointments, setAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    api.client
      .get(`/appointments`, {
        params: {
          start_date: startOfMonth(new Date()),
          end_date: endOfMonth(new Date()),
        },
      })
      .then((resp) => {
        setAppointments(resp.data?.sort((a, b) => compareAsc(new Date(a.datetime), new Date(b.datetime))) || []);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, [api.client]);

  const handleCancel = (appointment) => {
    setAppointments((prev) => {
      const res = prev.filter((a) => a.id !== appointment.id);
      return [...res];
    });
  };

  const handleReschedule = (appointment) => {
    setAppointments((prev) => {
      const res = prev.filter((a) => a.id !== appointment.id);
      return [...res, appointment].sort((a, b) => compareAsc(new Date(a.datetime), new Date(b.datetime)));
    });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!!appointments && appointments.length > 0 && (
            <Panel>
              <PanelHeader>
                <h1 className="text-xl font-bold tracking-tight text-gray-900">Upcoming Appointments</h1>
              </PanelHeader>
              <PanelBody>
                <ol className="mt-4 space-y-3 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                  {appointments.map((appointment) => (
                    <AppointmentRow
                      key={appointment.id}
                      appointment={appointment}
                      onCancel={handleCancel}
                      onReschedule={handleReschedule}
                    />
                  ))}
                </ol>
              </PanelBody>
            </Panel>
          )}
        </>
      )}
    </>
  );
};

export default MyAppointments;
