import { useRef } from "react";

const CancelAppointment = (props) => {
  const { onCancel, onSubmit } = props;

  const reasonRef = useRef();

  return (
    <div className="mt-5 p-3 ring-2 ring-slate-200 rounded-lg shadow-sm drop-shadow-lg">
      <div className="flex flex-col items-start">
        <textarea
          id="reason"
          name="reason"
          ref={reasonRef}
          rows={3}
          placeholder="Optional message for the physician e.g. reason for cancellation..."
          className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <div className="mt-5 flex flex-row-reverse gap-5">
        <button
          type="button"
          className="w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500"
          onClick={() => onSubmit(reasonRef.current.value)}
        >
          Cancel Appointment
        </button>
        <button
          type="button"
          className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={onCancel}
        >
          Don't Cancel
        </button>
      </div>
    </div>
  );
};

export default CancelAppointment;
